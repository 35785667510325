<template>
	<form action="">
		<b-row class="align-items-center justify-content-center">
			<b-col cols="12" md="10">
				<b-card title="Profile Admin">
					<div class="form-group">
						<label>Nama</label>
						<input type="text" class="form-control" v-model="form.name">
					</div>
					<div class="form-group">
						<label>Email</label>
						<input type="email" class="form-control" v-model="form.email">
					</div>
					<div class="form-group">
						<label>Phone</label>
						<input type="number" class="form-control" v-model="form.phone">
					</div>
					<div class="form-group">
						<label>Password</label>
						<input type="password" class="form-control" placeholder="..........." v-model="form.password">
					</div>
					<div class="form-group">
						<label>Password Confirmation</label>
						<input type="password" class="form-control" placeholder="..........." v-model="form.password_confirmation">
					</div>
				</b-card>
			</b-col>
			<b-col cols="12" md="10" class="align-items-center justify-content-center">
				<button
					variant="primary"
					class="btn waves-effect waves-float waves-light btn-primary"
					@click.prevent="saveProfile"
				>
					Save
				</button>
			</b-col>
		</b-row>
	</form>
</template>

<script>
	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
	import { 
		BCol,
		BRow,
		BCard,
		BCardText,
	} from 'bootstrap-vue'
	export default {
		components: {
			BCol,
			BRow,
			BCard,
			BCardText
		},
		data() {
			return {
				form: {
					name: '',
					email: '',
					phone: '',
					password: '',
					password_confirmation: '',
				}
			}
		},
		mounted() {
			this.loadProfile()
		},
		methods: {
			loadProfile() {
				this.$http.get('admin/me')
				.then(response => {
					const userData = response.data.data
					localStorage.setItem('userData', JSON.stringify(userData))
					this.form = userData
				})
			},
			saveProfile() {
				this.$http.post('admin/me', this.form)
				.then(response => {
					this.loadProfile()
					if (response) {
						this.$toast({
						component: ToastificationContent,
							props: {
								title: 'Edit data Succesfull',
								icon: 'EditIcon',
								variant: 'success',
							},
						})
						this.$router.push({ name: 'profile' })
					}
				})
			}
		}
	}
	
</script>